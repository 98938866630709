<template>
  <div class="visialDesign">
    <header>
      <top-title
      title="視覺設計"
      subTitle="">
      </top-title>
    </header>
    <page-main-body
      title="運用視覺影像，創造您的最大商業效益"
      subTitle="Use visual images to optimize your business"
      paragraph1="采億 ／ 采盟是擅長打造視覺影像的團隊，我們仔細傾聽您的需求，並針對您的需求進行客製化的分析及研究，盡全力執行到最高品質，為您的品牌量身打造最適合的企業形象。我們提供的服務主要包含：企業品牌、平面設計、廣告素材製作以及商業攝影。　">
    </page-main-body>
    <v-d-example
      type="enterpriseBrand"
      title="企業品牌"
      content="讓我們為您的公司 / 產品 / 品牌設計引人注目的企業形象（吉祥物、LOGO、名片…等），高質，評價，國際化。"
      to="caseStudies"
      param="enterpriseBrand">
    </v-d-example>
    <v-d-example
      type="graphicDesign"
      title="平面設計"
      content="我們能為您設計各類形式的創意作品，讓平凡的靜態圖變成活潑的動態圖，為您的品牌形象打造出獨特風格。"
      to="caseStudies"
      param="graphicDesign">
    </v-d-example>
    <v-d-example
      type="adMaterial"
      title="廣告素材"
      content="我們可以提供您的產品/品牌的各類形式廣告展現，提升知名度、增加更多客戶，全面幫您做到好。"
      to="caseStudies"
      param="adMaterial">
    </v-d-example>
    <v-d-example 
      type="businessFilming"
      title="商業攝影"
      content="我們堅持高品質的影像拍攝與精準的技術服務，以呈現客戶的產品美感和完美的企業形象。"
      to="caseStudies"
      param="commercialFilming">
    </v-d-example>
    <footer-view />
  </div>
</template>

<script>
import topTitle from "@/components/topTitle.vue";
import pageMainBody from "@/components/pageMainBody.vue";
import footerView from "@/components/footerView.vue";
import VDExample from "@/components/VDExample.vue";

export default {
  name: "visualDesign",
  components: {
    topTitle,
    pageMainBody,
    footerView,
    VDExample
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  mounted() {
    this.$parent.inPage = true;
  },
  destroyed() {
    this.$parent.inPage = false;
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      var st = window.pageYOffset || document.documentElement.scrollTop;
      var hook = window.innerHeight * 0.6;
      $(".VDExample").each(function() {
        if (
          st > $(this).offset().top - hook &&
          st < $(this).offset().top - window.innerHeight * 0.12
        ) {
          $(this).addClass("hover");
        } else {
          $(this).removeClass("hover");
        }
      });
    },
    goto(to) {
      this.$router.push(to)
    }
  }
};
</script>

<style>
header {
  position: relative;
  background-image: url("../../assets/image/service/visualDesign/topBanner.jpg");
  background-attachment: fixed;
  background-position: 50% 0%;
  width: 100vw;
  height: 600px;
}
</style>
