<template>
  <div class="VDExample" @mouseover="mouseover" @click="viewMore">
    <div class="viewMore">
      <span><font>觀</font>看<br/>
        相關案例
      </span>
    </div>
    <div class="image" :class="[type]"></div>
    <div class="description">
      <div class="box">
        <div class="title">{{title}}</div>
        <div class="content">{{content}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VDExample",
  props: {
    type: String,
    title: String,
    content: String,
    to: String,
    param: String
  },
  methods: {
    viewMore() {
      var vm = this;
      this.$router.push({ name: 'CaseStudies', params: { caseType: vm.param } });
    },
    mouseover() {
      //不給mouseover事件 CSS的hover無法在ios上觸發
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/style.scss";

.image {
  &.enterpriseBrand {
    background-image: url("../assets/image/service/visualDesign/enterpriseBrand.jpg");
  }
  &.graphicDesign {
    background-image: url("../assets/image/service/visualDesign/graphicDesign.jpg");
  }
  &.adMaterial {
    background-image: url("../assets/image/service/visualDesign/adMaterial.jpg");
  }
  &.businessFilming {
    background-image: url("../assets/image/service/visualDesign/businessFilming.jpg");
  }
}

.VDExample {
  cursor: pointer;
  position: relative;
  width: 100vw;
  height: 22vw;
  display: flex;
  margin: 25px 0;
  color: #fff;
  @media #{$tablet} {
    flex-direction: column;
    height: 100vw;
    max-height: 350px;
    .image {
      flex: 4;
    }
    .description {
      flex: 3;
    }
  }
  .viewMore {
    position: absolute;
    transition: 0.5s ease-out;
    background-color: #73cccf;
    height: 100%;
    width: 25%;
    left: -40%;
    top: 0;
    @media #{$tablet} {
      opacity: 0;
      width: 100%;
      height: 0;
      left: 0;
    }
    z-index: 5;
    text-align: left;
    span {
      transform: scale(1.2);
      @include font(large);
      position: absolute;
      top: 25%;
      left: 20%;
      @media #{$tablet} {
        left: 10%;
        top: 20%;
        transform: scale(1);
      }
      font {
        @include font(bigTitle);
      }
    }
  }
  .image {
    flex: 11;
    transition: 0.5s ease-out;
    background-position: 50% 50%;
    background-size: cover;
  }
  .description {
    flex: 8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    background-color: #999999;
    transition: 0.5s ease-out;
    padding: 25px;
    .box {
      margin-left: 6%;
      width: 80vw;
      max-width: 420px;
      .title {
        margin-bottom: 10px;
        @include font(medium);
        font-weight: 900;
      }
      .content {
        @include font(small);
        font-weight: 300;
      }
    }
  }
  &:hover {
    .viewMore {
      left: 0;
      @media #{$tablet} {
        opacity: 1;
        height: 30%;
        top: 0;
      }
    }
    .image {
      filter: brightness(0.5);
    }
    .description {
      background-color: #73cccf;
    }
  }
  @media #{$tablet} {
    &.hover {
      .viewMore {
        opacity: 1;
        height: 30%;
        top: 0;
      }
      .image {
        filter: brightness(0.5);
      }
      .description {
        background-color: #73cccf;
      }
    }
  }
}
</style>
